*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.chat-input {
  width: 100%;
  height: 100%;
  border: none;
  font: 400 .9rem 'Open Sans', serif;
  color: #4D4D4D;
  resize: none;
  outline: none;
}

.chat-input-desktop {
  width: 100%;
  height: calc(100% - 2.5rem);
  border: none;
  font: 400 .9rem 'Open Sans', serif;
  color: #4D4D4D;
  resize: none;
  outline: none;
}


/* video {
  max-width: 100%;
  max-height: 100%;
} */

@-ms-viewport{
  width: device-width;
}